'use client';

import Image from 'next/image';
import Link from 'next/link';
import { parseAsString, useQueryStates } from 'nuqs';
import { Suspense, useActionState, useEffect } from 'react';
import { z } from 'zod';

import type { LoginProps } from '@voyage-lab/core-auth';
import { Button, Divider, Icon, Layout, Spacer, Text, notify } from '@voyage-lab/ui';
import { signIn } from '@voyage-lab/web';

import { assets } from '../../../assets';
import { FormButton, FormTextInput } from '../../../components/form';
import { PasskeySignInForm } from '../form';

type LoginFormProps = {
	callbackUrl: string;
	resetLink?: string;
	challenge?: string;
	enableGoogleSignIn?: boolean;
} & Pick<LoginProps, 'app'>;

export function LoginForm({ callbackUrl, resetLink, challenge, enableGoogleSignIn, app }: LoginFormProps) {
	const [state, formAction] = useActionState(handleSignIn, {
		error: '',
	});

	useEffect(() => {
		if (state?.error) notify.error(state.error);
	}, [state]);

	return (
		<Layout className="p-card">
			<Suspense>
				<TokenLoginForm callbackUrl={callbackUrl} />
			</Suspense>
			<Text.Heading size={'2xl'} weight={'bolder'} className="text-center">
				Log in to LiveRecover
			</Text.Heading>
			<Spacer vertical={40} />
			<Layout display={'flex'} direction={'col'} gap={'1'} className="w-[360px]">
				<form action={formAction} aria-label="Login form">
					<input type="hidden" name="provider" defaultValue={'credentials'} />
					<Layout display={'flex'} gap={'2'} direction={'row'}>
						<FormTextInput
							required
							name="email"
							type="email"
							fullWidth
							autoComplete="username"
							innerPrefix={<Icon name="Mail" color="grey-1" />}
							placeholder="Email"
						/>
					</Layout>
					<FormTextInput
						fullWidth
						type="password"
						name="password"
						autoComplete="current-password"
						required
						innerPrefix={<Icon name="KeyRound" color="grey-1" />}
						placeholder="Password"
					/>
					<Spacer vertical={15} />
					<Layout display={'flex'} gap={'2'} direction={'row'}>
						<FormButton type="submit" fullWidth>
							Log in
						</FormButton>

						{challenge && typeof window !== 'undefined' && <PasskeySignInForm challenge={challenge} />}
						{challenge && typeof window === 'undefined' && (
							<Button
								formNoValidate
								fullWidth={false}
								variant={'secondary'}
								leftIcon={{
									name: 'Fingerprint',
								}}
							>
								Passkey
							</Button>
						)}
					</Layout>
				</form>
				{!!resetLink && (
					<Layout display={'flex'} align={'center'}>
						<Link href={resetLink}>
							<Button variant={'link'}>Forgot password?</Button>
						</Link>
					</Layout>
				)}
				{enableGoogleSignIn && (
					<>
						<Layout display={'flex'} align={'center'} gap={'2'} className="my-3">
							<Divider />
							<Text size={'sm'} weight={'bold'} variant="primary">
								OR
							</Text>
							<Divider />
						</Layout>
						<form action={formAction}>
							<input type="hidden" name="provider" defaultValue={'google'} />

							<FormButton size={'lg'} fullWidth variant={'outline'}>
								<Image alt="google" height="20" width="20" src={assets.google_logo} />
								Sign in with Google
							</FormButton>
						</form>
					</>
				)}
				<Spacer vertical={10} />
				<Text size={'sm'} variant={'secondary'} className="text-center">
					By clicking the button above, I agree to LiveRecover Terms of Service and Privacy Policy.
				</Text>
			</Layout>
			<Spacer vertical={20} />
		</Layout>
	);

	async function handleSignIn(prev: unknown, form: FormData) {
		const formData = Object.fromEntries(form.entries());
		const validData = loginSchema.safeParse(formData);

		if (!validData.success) return { error: validData.error.message };

		await signIn({
			...validData.data,
			provider: validData.data.provider,
			redirectUrl: callbackUrl,
			app,
		});
	}
}

export function TokenLoginForm({ callbackUrl }: { callbackUrl: string }) {
	const [params] = useQueryStates({
		token: parseAsString,
	});

	useEffect(() => {
		if (!params.token) return;

		handleSignIn(params.token, callbackUrl);
	}, [params.token]);

	async function handleSignIn(token: string, callbackUrl: string) {
		await signIn({
			provider: 'credentials',
			token,
			redirectUrl: callbackUrl,
		});
	}

	return <></>;
}

const loginSchema = z.discriminatedUnion('provider', [
	z.object({
		provider: z.literal('credentials'),
		email: z.string().email(),
		password: z.string(),
	}),
	z.object({
		provider: z.literal('google'),
	}),
]);
